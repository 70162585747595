@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
/* Variáveis*/
:root {
  --colorDefault: #276efa;
  --colorDefaultHover: #d10100;
  --colorText: #404040;
  --colorText2: #888888;
  --backgroundDefault: #f8f8f8;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: var(--colorDefault); }

html, body, #root {
  min-height: 100%;
}

body {
  background: #f8f8f8;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
}
a:hover {
  text-decoration: none;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none !important;
}
body, input, button {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}
.form-control {
  padding-top: 20px;
  height: 55px;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c7c7c7;
  outline: 0;
  box-shadow: none;
}
button:focus {
  outline: none;
}
form {
  display: flex;
  flex-direction: column;
}
form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 8px;
}
.labelInput {
  position: absolute;
  margin-left: 10px;
  margin-top: 2px;
  font-size: 12px;
}
.labelInputInner {
  position: absolute;
  bottom: 0;
  font-size: 16px;
  margin-left: 15px;
  font-weight: bold;
}
.labelImage {
  font-size: 14px;
  background: white;
  /*float: left;*/
}
form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}
form input {
  border: 1px solid #ddd;
}
.btn {
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  background: var(--colorDefault);
  color: #fff;
  cursor: pointer;
  padding: 12px 15px;
}
.btn-default {
  background: var(--colorDefault);
  color: #fff;
}

.btn-default:hover {
  background: var(--colorDefaultHover);
  color: #fff;
}
.btn-delete {
  color: #fff;
  background-color: #424242;
  border-color: #424242;
}
.btn-delete:hover {
  background: #212121;
  border-color: #212121;
  color: #fff;
}
.btn-input {
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px 20px;
  padding: 8px 12px;
  font-size: 14px;
}
.btn-closeModal {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  z-index: 99;
  color: var(--colorText);
  border: 0;
  background: transparent;
  padding: 5px;
  font-size: 25px;
}

.hidden {
  display: none;
}

.v-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.containerLoading {
  position: fixed;
  z-index: 99;
  background: rgba(255,255,255,0.85);
  flex: 1;
  height: 100%;
  width: 100%;
  margin-left: -15px;
}

/* Checkbox Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: var(--colorDefault);
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
} 

.card {
  --webkit-box-shadow: 0px 2px 8px -2px rgb(177 177 177);
  box-shadow: 0px 2px 8px -2px rgb(177 177 177);
}