@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
.login {
  /*margin: 150px auto 0;
  max-width: 450px;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}
#logo {
  width: 130px;
  margin-bottom: 15px;
}
.content {
  /*width: 100%;*/
  margin-top: 20px;
  border-radius: 10px;
  padding: 30px;
  border: 2px solid rgb(245, 245, 245);
  box-shadow: 0px 15px 10px -6px rgb(177, 177, 177);
  /*text-align: center;*/
  margin: 15px;
}
.content > p {
  color: rgb(153, 153, 153);
}
.content > h1 {
  color: rgb(109, 109, 109);
  text-transform: uppercase;
  font-size: 30px
}
.login .content {
  max-width: 450px;
  width: 100%;
}
/* Variáveis*/
:root {
  --colorDefault: #276efa;
  --colorDefaultHover: #d10100;
  --colorText: #404040;
  --colorText2: #888888;
  --backgroundDefault: #f8f8f8;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: #276efa; background: var(--colorDefault); }

html, body, #root {
  min-height: 100%;
}

body {
  background: #f8f8f8;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
}
a:hover {
  text-decoration: none;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none !important;
}
body, input, button {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}
.form-control {
  padding-top: 20px;
  height: 55px;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c7c7c7;
  outline: 0;
  box-shadow: none;
}
button:focus {
  outline: none;
}
form {
  display: flex;
  flex-direction: column;
}
form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 8px;
}
.labelInput {
  position: absolute;
  margin-left: 10px;
  margin-top: 2px;
  font-size: 12px;
}
.labelInputInner {
  position: absolute;
  bottom: 0;
  font-size: 16px;
  margin-left: 15px;
  font-weight: bold;
}
.labelImage {
  font-size: 14px;
  background: white;
  /*float: left;*/
}
form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}
form input {
  border: 1px solid #ddd;
}
.btn {
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  background: #276efa;
  background: var(--colorDefault);
  color: #fff;
  cursor: pointer;
  padding: 12px 15px;
}
.btn-default {
  background: #276efa;
  background: var(--colorDefault);
  color: #fff;
}

.btn-default:hover {
  background: #d10100;
  background: var(--colorDefaultHover);
  color: #fff;
}
.btn-delete {
  color: #fff;
  background-color: #424242;
  border-color: #424242;
}
.btn-delete:hover {
  background: #212121;
  border-color: #212121;
  color: #fff;
}
.btn-input {
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px 20px;
  padding: 8px 12px;
  font-size: 14px;
}
.btn-closeModal {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  z-index: 99;
  color: #404040;
  color: var(--colorText);
  border: 0;
  background: transparent;
  padding: 5px;
  font-size: 25px;
}

.hidden {
  display: none;
}

.v-center {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.containerLoading {
  position: fixed;
  z-index: 99;
  background: rgba(255,255,255,0.85);
  flex: 1 1;
  height: 100%;
  width: 100%;
  margin-left: -15px;
}

/* Checkbox Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #276efa;
  background-color: var(--colorDefault);
}
input:checked + .slider:before {
  transform: translateX(26px);
} 

.card {
  --webkit-box-shadow: 0px 2px 8px -2px rgb(177 177 177);
  box-shadow: 0px 2px 8px -2px rgb(177 177 177);
}
.barraSuperior, .barraSuperior.affix {
  background: var(--backgroundDefault);
  color: var(--colorText);
  z-index: 999;
  padding: 15px;
  box-shadow: 0px 5px 5px -2px #e0e0e0;
}
.barraSuperior .logo {
  /*padding-left: 20px;*/
  color: var(--colorText);
}
.barraSuperior .imgMenu #btnMenu {
  cursor: pointer;
  color: var(--colorText);
}
#menuUser {
  background: var(--backgroundDefault);
  color: var(--colorText);
  position: fixed;
  right: 0;
  top: 60px;
  height: 100%;
  z-index: 999;
  width: auto;
  padding: 0px;
  visibility: hidden;
  transform: translateX(250px);
  transition: all 0.3s ease;
  box-shadow: 0px 10px 10px -2px var(--colorText);
}
#menuUser.active {
  visibility: visible;
  transform: translateX(0px);
}
#menuUser li{
  padding: 15px 40px 15px 25px;
  list-style: none;
  width: 100%;
  cursor: pointer;
}
#menuUser li.active, #menuUser li:hover{
  background: var(--colorDefault);
  color: white;
}
#menuUser li span,  #menuUser li svg{
  margin-right: 15px;
}
#menuUser li img {
  margin-top: -5px;
}
#logo-barra{
  width: 30px;
}
#titleMenu {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: var(--colorText);
}
.bodyDashboard {
  margin: 0px auto 0;
  padding-top: 15px;
  max-width: 750px;
  width: 100%;
  height: 100%;
  max-height: 92vh;
  overflow: scroll;
  overflow-x: hidden;
}
.itensHome {
  width: 100%;
  height: 80vh;
}
.itensHome .title{
  margin-top: 30px;
  margin-left: 15px;
}
.itemHome {
  padding: 20px 15px;
  margin: 5px;
  box-shadow: 0px 0px 8px 0px rgb(152 152 152);
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
}
.itemHome span{
  color: var(--colorText2);
}
.itemHome .itemHomeIcon{
  color: var(--colorText);
}
.itemHome .title{
  margin-top: 20px;
  color: var(--colorText);
  font-weight: bold;
  font-size: 25px;
  margin-left: 0;
}
.dashboardList .itemDashboardList, .musicList .itemMusicList {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 15px;
  background: rgb(253, 253, 253);
  /*border: 2px solid rgb(245, 245, 245);*/
  box-shadow: 0px 2px 8px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
}
.userList .itemUserList {
  margin-bottom: 0px;
  margin-top: 8px;
  border-radius: 5px;
  padding: 10px 10px;
  background: rgb(253, 253, 253);
  box-shadow: 0px 2px 5px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
}
.dashboardList .itemDashboardListActive{
  box-shadow: 0px 0px 10px 1px #636363;
  /*paddingbox-shadow: 0px 0px 2px 3px var(--colorDefault);
  border: 0;*/
}
.headList {
  cursor: pointer;
}
.dashboardList .itemDashboardList .iconItem, .musicList .itemMusicList .iconItem{
  margin: 5px;
  color: dimgrey;
}
.dashboardList .itemDashboardList .edit{
  color: #01d401;
}
.dashboardList .itemDashboardList .delete{
  color: #888888;
}
.dashboardList .itemDashboardList .itemImg {
  width: 100%;
  max-width: 160px;
}
.dashboardList .itemDashboardList label#thumbnail{
  display: flex;
  width:100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.dashboardList .itemDashboardList label#background{
  width:100%;
  height: 350px;
  margin-bottom: 1rem;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.dashboardList .itemDashboardList .itemDashboardListSwitch {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: -10px;
  z-index: 10;
}
.linkListItem {
  text-decoration: underline;
  font-weight: bold;
  margin-top: -14px;
  position: absolute;
  right: 0;
  margin-right: 35px;
  color: var(--colorDefault);
}
/*.musicList .itemMusicList label#thumbnail {
  width: 100%;
  height: 250px;
  margin-bottom: 1rem;
  border: 1px dashed #ddd;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}*/
audio {
  margin-left: 15px;
  margin-right: 15px;
  height: 40px;
}
#audio-upload[type="file"] {
  display: none;
}
label#thumbnail input, label#background input, label#imageShare input {
  display: none;
}
label#thumbnail.has-thumbnail, label#background.has-thumbnail, label#imageShare.has-thumbnail {
  border: 0 !important;
}
label#thumbnail img, label#background img, label#imageShare img {
  /*top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -100%);*/
}
label#thumbnail.has-thumbnail img, label#background.has-thumbnail img, label#imageShare.has-thumbnail img{
  display: none;
}
.title {
  color: var(--colorText);
  font-weight: 700;
  font-size: 20px;
  width: 100%;
}
.labelFilter {
  color: var(--colorText);
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
}
.iconFilter {
  color: var(--colorText);
}
.btnNew {
  margin: 5px 0px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  width: 50px;
  height: 50px;
  background: var(--colorDefault);
  color: #ffffff;
  box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.btnDeleteImg {
  position: absolute;
  margin-top: 92px;
  margin-left: 27px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  width: 30px;
  height: 30px;
  background: #f8f8f8;
  color: var(--colorDefault);
  box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.iconInputRight{
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 25px;
  margin-top: 13px;
  color: #ababab;
}
.iconSearchInput {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 30px;
  margin-top: 20px;
  color: #ababab;
}
.contentEditor {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.contentEditor .inputEditor {
  padding: 15px;
}
.imagesPreview {
  display: inline-block;
  width: 130px;
  height: 130px;
  margin: 10px;
  border-radius: 10px;
  background-size: cover !important;
  background-position: center !important;
  cursor: pointer;
}
#labelImages {
  cursor: pointer;
}
#formNotification{
  width: 100%;
}
.idYoutubeSelected {
  margin-bottom: 5px;
  margin-left: 7px;
  padding: 4px;
  background-color: #ddd;
  border-radius: 5px;
  /*width: 80px;*/
}
.idYoutubeSelected .btnDeleteIdYoutube {
  margin-left: 8px;
  cursor: pointer;
}
.categoryListBody {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.categoryItemListBody {
  height: 120px;
  width: 130px;
  border-radius: 15px;
  border: 2px dashed #A5A5A5;
  padding: 10px;
  justify-content: center;
  text-align: center;
  margin: 10px;
  cursor: pointer;
}  
.categoryItemListBodyActive {
  background: var(--colorDefault);
  border-color: var(--colorDefault);
  border-style: solid;
}
.categoryItemTextListBody {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
  line-height: 1.3em;
}
.categoryItemTextListBodyActive {
  color: #fff;
  font-weight: bold;
}
.categoryItemIconistBody {
  color: #a0a0a0;
  margin-top: 10px;
}
.categoryItemIconistBodyActive {
  color: #fff;
  margin-top: 10px;
}
 
/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  /*#logo-barra{
    width: 60px;
  }*/
  .dashboardList .itemDashboardList label#thumbnail{
    width:100%;
    height: 160px;
  }
  .itensHome {
    height: auto;
  }
}
/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: rgb(185, 185, 185) }

#homeIndex h1, #homeIndex h2, #homeIndex h3, #homeIndex h4, #homeIndex h5, #homeIndex h6, #homeIndex p{
  color: #fff;
}

#homeIndex {
  /*background-image: url('../../assets/background.jpg');*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

#homeIndex .navbar{
  padding: 15px 0;
}

#homeIndex a {
  color: white;
  text-transform: uppercase;
  line-height: normal;
  font-weight: bold;
}

#homeIndex .bodyIndex {
  padding: 60px 0;
  height: 95vh;
  place-content: stretch;
}

#homeIndex .bodyIndex .logo img{
  max-width: 180px;
}

#homeIndex .bodyIndex .card p{
  color: #004db9;
  margin-bottom: 0;
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
}

#homeIndex .bodyIndex button {
  padding: 12px 35px;
}
#homeIndex p {
  font-size: 16px;
}
#homeIndex .footerIndex {
  padding: 15px 0;
  background: #f8f8f8;
}
#homeIndex .footerIndex p, a {
  color: #353535;
  text-transform: none;
  margin-bottom: 8px;
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .itensHome {
    height: auto;
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 552px) {
  #homeIndex a {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
  }
}
