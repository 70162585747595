/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: rgb(185, 185, 185) }

#homeIndex h1, #homeIndex h2, #homeIndex h3, #homeIndex h4, #homeIndex h5, #homeIndex h6, #homeIndex p{
  color: #fff;
}

#homeIndex {
  /*background-image: url('../../assets/background.jpg');*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

#homeIndex .navbar{
  padding: 15px 0;
}

#homeIndex a {
  color: white;
  text-transform: uppercase;
  line-height: normal;
  font-weight: bold;
}

#homeIndex .bodyIndex {
  padding: 60px 0;
  height: 95vh;
  place-content: stretch;
}

#homeIndex .bodyIndex .logo img{
  max-width: 180px;
}

#homeIndex .bodyIndex .card p{
  color: #004db9;
  margin-bottom: 0;
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
}

#homeIndex .bodyIndex button {
  padding: 12px 35px;
}
#homeIndex p {
  font-size: 16px;
}
#homeIndex .footerIndex {
  padding: 15px 0;
  background: #f8f8f8;
}
#homeIndex .footerIndex p, a {
  color: #353535;
  text-transform: none;
  margin-bottom: 8px;
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .itensHome {
    height: auto;
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 552px) {
  #homeIndex a {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
  }
}