.login {
  /*margin: 150px auto 0;
  max-width: 450px;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}
#logo {
  width: 130px;
  margin-bottom: 15px;
}
.content {
  /*width: 100%;*/
  margin-top: 20px;
  border-radius: 10px;
  padding: 30px;
  border: 2px solid rgb(245, 245, 245);
  box-shadow: 0px 15px 10px -6px rgb(177, 177, 177);
  /*text-align: center;*/
  margin: 15px;
}
.content > p {
  color: rgb(153, 153, 153);
}
.content > h1 {
  color: rgb(109, 109, 109);
  text-transform: uppercase;
  font-size: 30px
}
.login .content {
  max-width: 450px;
  width: 100%;
}